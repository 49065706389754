import React, { useState, useEffect } from 'react';

import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';

const PopularDomainChart = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    async function fetchData() {
      const response = await fetch('https://my-workers-project.himanshikhatri1371999.workers.dev/popular-domain');
      const jsonData = await response.json();
      setData(jsonData);
    }
    fetchData();
  }, []);

  

  return (
    <>
    <h2>Popular Domain</h2>
    <table>
      <thead>
        <tr>
          <th>Rank</th>
          <th>Domain</th>
          <th>Category</th>
          <th>Rank Changed</th>
        </tr>
      </thead>
      <tbody>
        {data.rankingEntries?.map((entry) => (
          <tr key={entry.domain}>
            <td>{entry.rank}</td>
            <td>{entry.domain}</td>
            <td>{entry.Category}</td>
            <td>{entry.rankChange ? 'Yes' : 'No'}</td>
          </tr>
        ))}
      </tbody>
    </table>
    </>
   
  );
};

export default PopularDomainChart;
