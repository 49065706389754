import logo from './logo.svg';
import './App.css';
import TrafficChangeChart from './components/TrafficChangeChart';
import PopularDomainChart from './components/PopularDomainChart';
import AttackLayer3 from './components/AttackLayer3';

function App() {
  return (
    <div>
      <h1 style={{textAlign:"center"}}>Dashboard</h1>
      <TrafficChangeChart/>
      <PopularDomainChart/>
      <AttackLayer3/>
    </div>
  );
}

export default App;
