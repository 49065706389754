import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const App = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    async function fetchData() {
      const response = await fetch('https://my-workers-project.himanshikhatri1371999.workers.dev/attack-layer3');
      const jsonData = await response.json();
      setData(jsonData);
    }
    fetchData();
  }, []);

  const totalData = data.data && data.data.total ? Object.values(data.data.total) : [];

  const totalTimestamps = totalData[0];
  const totalValues = totalData[1];
  const totalChartData = totalTimestamps?.map((timestamp, index) => ({
    time: timestamp,
    total_traffic_change: totalValues[index],
  }));


  return (
    <>
    <h2>Attack-Layer3</h2>
    <LineChart width={600} height={300} data={totalChartData}>
      <XAxis dataKey="time" />
      <YAxis />
      <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="total_traffic_change" stroke="#8884d8" />
    </LineChart>
    </>
   
  );
};

export default App;
